import { useEffect } from 'react';
import './App.css';

function App() {
  const getQueryParams = (param) => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(param);
  };

  const token = getQueryParams('emailToken');

  console.log(token);

  const postData = async () => {
    try {
      const response = await fetch(
        `https://yours-backend.onrender.com/api/v1/users/verify-single-user-email-details?emailToken=${token}`,
        {
          method: 'GET', // Specifies the request method
          headers: {
            'Content-Type': 'application/json', // Specifies the content type in the headers
          },
        }
      );
      const jsonResponse = await response.json(); // Parses JSON response into native JavaScript objects
      return jsonResponse; // Returns the response data
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  useEffect(() => {
    if (token) {
      postData();
    }
  }, [token]);

  return (
    <div className='main'>
      <div className='logoCont'>
        <img
          className='logo'
          src={require('./assets/images/logo.png')}
          alt='Logo'
        />
      </div>
      <div className='content'>
        <img
          className='tick_icon'
          src={require('./assets/images/whitecircle.png')}
          alt='Tick'
        />
        <p className='welcome'>You're all set!</p>
        <p className='congratulations' style={{ fontFamily: 'Helvetica, sans-serif' }}>Congratulations!</p>
        <p className='text' style={{ fontFamily: 'Helvetica, sans-serif' }}>
          Open the YOURS app and begin your writing journey! Let's go!
        </p>
      </div>
    </div>
  );
}

export default App;
